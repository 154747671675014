import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '../../../case.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CreditorService } from '../../../../creditor/creditor.service';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CasePaymentPlanService } from '../../../case-payment-plan.service';

@Component({
  templateUrl: './win-amount.component.html',
})
export class WinAmountComponent implements OnInit {
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading = 0;
  public caseCreditors: CaseCreditor[] = [];

  constructor(public dialogRef: MatDialogRef<WinAmountComponent>,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private toast: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private creditorService: CreditorService,
              public translateService: TranslateService,
              private configService: MiscConfigService,
              private caseCreditorService: CaseCreditorService,
              private casePaymentPlanService: CasePaymentPlanService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.getClaimFeePercent();
    this.data.case.creditors.forEach(creditor => {
      this.caseCreditors.push({
        id: creditor.pivot.id,
        reference_number: creditor.pivot.reference_number,
      });
    });
    console.log(this.data);
  }

  buildForm(claimWinPercent = null) {
    this.form = this.fb.group({
        case_creditor_claim_ref: [null, Validators.required],
        win_amount: [null, Validators.required],
        win_percent: [this.data.debt ? this.data.debt.percentage_agreed  : claimWinPercent.value, Validators.required],
        claim_fee: [null, Validators.required],
        is_from_procurador: [null],
    });
    // watch for value changes in the win_amount field
    this.form.get('win_amount').valueChanges.subscribe(value => {
      if (value && this.form.get('win_percent').value) {
        // include 21% VAT
        let claimFee = value * (this.form.get('win_percent').value / 100) * 1.21;
        this.form.get('claim_fee').setValue(claimFee.toFixed(2));
      }
    });
    
    this.formActive = true;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  submit() {
    if (this.form.valid) {
      this.isLoading++;

      const formData = this.form.getRawValue();
      formData.payment_plan_type_id = 5;
      formData.win_type = 'cash';
      formData.win_amount = +formData.win_amount;
      formData.phases = [
        {
            payment_plan_type_id: 5,
            start_from_type: 'date',
            start_from: new Date().toISOString(),
            amount: formData.claim_fee,
            split_amount_type: 'equally',
            installment_amount: Number(formData.claim_fee),
            installments_count: 1,
            interval: null,
            interval_unit: 'month'
        }
      ]

      // iterate of the claim_fees object and add the id to the formData
      this.data.creditors.forEach(creditor => {
        if (creditor.id === formData.case_creditor_claim_ref) {
          console.log(creditor);
          if(creditor.claim_fees?.length > 0) {
            formData.claim_fee_id = creditor.claim_fees[0].id;
          }
        }
      });

      formData.case_creditor_ids = [formData.case_creditor_claim_ref];

      this.casePaymentPlanService.storePaymentPlan(this.data.case.id, formData)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            // this.generatedPaymentPlan = result.data;
            this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
              {model: 'Additional payment plan'}));
            this.dialogRef.close({dismissed: false});
          },
          error => {
            // this.serverResponse = error.error;
            this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
              {model: 'Additional payment plan'}));
          },
        );
    } else {
      this.form.markAllAsTouched();
    }
  }

  public getClaimFeePercent() {
    this.configService.getConfigData().subscribe(next => {
        const claimWinPercent = next.data.find(obj => obj.key === 'claim_win_percent');
        this.buildForm(claimWinPercent);
      });
  }

}
